<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input style="width: 200px!important;" class="w120 mb10 mr10" placeholder="类型名称" v-model="filterName"
                  clearable/>
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button class="ma" type="primary" @click="addType">+ 添加类型</el-button>
      </div>
    </div>
    <commonTable :tableData="tableData" @handleSelectionChange="handleSelectionChange" :total="total"
                 @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"
                 :currentPage="currentPage"
                 :loading="loading">
      <template v-slot:table>
        <el-table-column type="selection"/>
        <el-table-column prop="name" align="center" label="类型名称"/>
        <el-table-column prop="" align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，分类排序越靠前" placement="right-start">
              <el-input v-model="scope.row.orderBy" min="0"
                        @input="scope.row.orderBy = scope.row.orderBy.replace(/^0(0+)|[^\d]+/g, '')"
                        placeholder="数值越大，分类排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="uploadTime" align="center" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch @change="saveStatus(scope.row)" v-model="scope.row.status" active-color="#51CDCB"
                       inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="open(scope.row)">编辑</el-button>
            <!-- <el-button type="primary" plain @click="openType(scope.row)">文章分类</el-button> -->
            <customPopconfirm v-if="scope.row.ifDelete != 1" class="ma ml10 mb10" confirm-button-text="确定"
                              cancel-button-text="取消" @confirm="del(scope.row.articleTypeId)" title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog title="添加类型" v-if="centerDialogVisible" :visible.sync="centerDialogVisible" :modal-append-to-body="false"
               width="50%" center @close="close1">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
        <el-form-item label="类型" prop="identificaType">
          <el-radio-group v-model="ruleForm.identificaType">
            <el-radio :label="0">文章</el-radio>
            <el-radio :label="4">视频</el-radio>
            <el-radio :label="5">广告图</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="类型名称" prop="title">
          <el-input clearable v-model="ruleForm.title" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="title">
          <el-input clearable v-model="ruleForm.sort" min="0"
                    @input="ruleForm.sort = ruleForm.sort.replace(/^0(0+)|[^\d]+/g, '')" maxlength="11"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="子类型" v-if="ruleForm.identificaType != 5">
          <div class="childTypeList" v-for="(item, index) in childTypeList" :key="index">
            <div class="childTypeListL">
              <el-input type="text" v-model="item.name" placeholder="请添加子分类" style="margin-right: 10px;">
              </el-input>
              <el-input type="number" @input="numIpt(item, index)" v-model="item.orderBy"
                        placeholder="请添加序号"></el-input>
            </div>
            <div class="childTypeListR">
              <el-button type="danger" @click="deleteChildType(item, index)">删除</el-button>
            </div>
          </div>
          <el-button type="primary" icon="el-icon-plus" @click="addChildType">添加子分类</el-button>
        </el-form-item>
        <el-form-item label="状态" prop="isState">
          <el-radio-group v-model="ruleForm.isState">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="determine">确 定</el-button>
            </span>
    </el-dialog>
    <el-dialog title="文章分类" :visible.sync="activeTypeDialogVisible" :modal-append-to-body="false" width="50%" center
               @close="close">
      <el-form :model="ruleFormType" ref="ruleForm1" label-width="150px">
        <el-form-item label="选择子分类" prop="isState">
          <el-select @change="childTypeChang" v-model="ruleFormType.value" placeholder="请选择子分类">
            <el-option v-for="item in queryChildType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <span style="color: red;text-align: center;display: block;margin-bottom: 20px;">
                    提示： 您的主类型下已经有{{ typeTole }}篇文章，该操作需要将主类型下的{{ typeTole }}文章转移到您选择的子分类下！
                </span>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <el-button @click="activeTypeDialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  addArticleType,
  selectArticleTypePageList,
  upArticleType,
  delArticleType,
  queryChildType,
  belongArticleType,
  selectWorkDesc
} from "@/api/content";
import console from "console";

export default {
  name: "lanmuList",
  components: {
    commonTable,
    customPopconfirm
  },
  props: {
    jurisdiction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      centerDialogVisible: false,
      activeTypeDialogVisible: false,
      typeTole: 0,
      childTypeList: [],
      queryChildType: [],
      articleTypeId1: '', //
      articleTypeId2: '', //
      ruleFormType: {
        value: ''
      },
      ruleForm: {
        title: "",
        sort: "",
        isState: 0,
        identificaType: 0
      },
      rules: {
        title: [{
          required: true,
          message: "请输入类型名称",
          trigger: "blur"
        }],
        sort: [{
          required: true,
          message: "请输入序号",
          trigger: "blur"
        },],
        isState: [{
          required: true,
          message: "请选择状态"
        }],
        identificaType: [{
          required: true,
          message: "请选择类型"
        }]
      },

            userInfo: this.$store.state.loginRoot.userInfo,
            options: [{
                label: "全部",
                value: 1
            }],
            tableData: [],
            filterName: "",
            row: "",
            delGoodsT: [],
            currentPage: 1,
            loading: false,
            total: 0, //总条数
            pageNum: 1,
            pageSize: 10,
            upDate: 1,
            articleTypeId: '',
            TypeData: '',
            feilei: 0,
            chooseIn: 0,
        };
    },
    mounted() {
        this.queryPage();
    },
    methods: {
        // 升
        litre(item, index) {
            console.log(index)
        },
        // 降
        drop(item, index) {
            console.log(index)
        },
        numIpt(item, index) {
            if (item.orderBy <= 0) {
                this.childTypeList[index].orderBy = ''
            }
        },
        //
        childType(item, index) {
            this.childTypeList[index].orderBy = index + 1
        },
        // 删除子分类
        async deleteChildType(item, index) {
            if (item.articleTypeId) {
                const res = await delArticleType({
                    articleTypeId: item.articleTypeId
                });
                if(res.code == 200){
                    this.$message.success(res.message);
                    this.childTypeList.splice(index, 1);
                }else{
                    this.$message.error(res.message);
                }
            } else {
                this.childTypeList.splice(index, 1);
            }
        },
        // 添加子分类
        addChildType() {
            this.childTypeList.push({
                name: '',
                orderBy: ''
            })
        },
        // 添加分类
        addType() {
            this.centerDialogVisible = true
            this.ruleForm.title = ''
            this.ruleForm.sort = ''
            this.childTypeList = []
            this.ruleForm.isState = 0
            this.upDate = 1;
            this.ruleForm.identificaType = 0;
        },
        async queryPage(payload) {
            let data = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                associationId: localStorage.getItem("associationId"),
                type: 1,
                ...payload
            };
            try {
                this.loading = true;
                const result = await selectArticleTypePageList(data);
                this.loading = false;
                const {
                    total,
                    list
                } = result.data.pageInfo;
                for (let i in list) {
                    if (list[i].status == 0) {
                        list[i].status = true
                    } else {
                        list[i].status = false
                    }
                    list[i].ifTop = list[i].ifTop == 0 ? true : false;
                    for (let j in this.typeList) {
                        if (list[i].classType == this.typeList[j].articleTypeId) {
                            list[i].classType = this.typeList[j].name
                        }
                    }
                }
                this.tableData = list;
                this.total = total;
            } catch (error) {
                this.tableData = []
                this.loading = false;
            }
        },
        // 子类列表
        async childTypePage() {
            // queryChildType
            let data = {
                associationId: localStorage.getItem("associationId"),
                articleTypeId: this.articleTypeId1
            }
            try {
                this.loading = true;
                const result = await queryChildType(data);
                this.loading = false;
                if (result.code == '200') {
                    if (result.data.total > 0) {
                        this.typeTole = result.data.total
                        this.activeTypeDialogVisible = true
                    } else {
                        this.$message({
                            type: "error",
                            message: "当前主分类下无文章",
                        });
                    }
                }

        result.data.childType.forEach(item => {
          item.value = item.articleTypeId
          item.label = item.name
        })
        this.queryChildType = result.data.childType
      } catch (error) {
        this.queryChildType = []
        this.loading = false;
      }
    },
    //分类弹窗
    open(row) {
      this.ruleForm.title = row.name;
      this.ruleForm.sort = row.orderBy;
      this.articleTypeId = row.articleTypeId
      this.ruleForm.isState = row.status ? 0 : 1;
      this.upDate = 2
      this.centerDialogVisible = true
      this.ruleForm.identificaType = row.identificaType;
      if (row.articleTypeChilds) {
        this.childTypeList = row.articleTypeChilds
      } else {
        this.childTypeList = []
      }
    },
    // 文章分类弹窗
    openType(row) {
      this.ruleFormType.value = ''
      this.TypeData = row
      this.articleTypeId1 = row.articleTypeId
      this.childTypePage();
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    childTypeChang(item) {
      if (this.feilei == 1) {
        console.log(this.ruleFormType.value)
      } else {
        let arr = this.TypeData.articleTypeChilds.filter(item1 => {
          if (item == item1.articleTypeId) {
            return item1;
          }
        })
        this.TypeData.articleTypeChilds = arr
      }
    },
    async saveStatus(row) {
      if (row) {
        let ajax = {
          articleTypeId: row.articleTypeId,
          status: row.status ? 0 : 1
        }
        const result = await upArticleType(ajax);
        this.$message({
          type: "success",
          message: "保存成功",
        });
        let data = {
          name: this.filterName
        };
        await this.queryPage(data);
      } else {
        this.$message({
          type: "error",
          message: "状态不能为空",
        });
      }
    },
    //删除弹窗
    async del(val) {
      try {
        const res = await delArticleType({
          articleTypeId: val
        });
        if(res.code == 200){
          await this.queryPage();
        }else{
          this.$message.error(res.message)
        }
      } catch (error) {
        console.log(error);
      }
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    // 提交文章类型
    async onSubmit() {
      try {
        if (!this.ruleFormType.value) {
          return this.$message({
            type: "error",
            message: "请选择后再提交",
          });
          ;
        }
        if (this.feilei == 1) {
          this.activeTypeDialogVisible = false
          console.log(this.childTypeList)
          for (let i in this.childTypeList) {
            delete this.childTypeList[i]['isMyself']
            if ((this.ruleFormType.value - 1) == i) {
              this.childTypeList[i]['isMyself'] = 0
            }
          }
          this.chooseIn = 1
          console.log(this.childTypeList)
        } else {
          let data = this.TypeData
          await belongArticleType(data);
          this.activeTypeDialogVisible = false
          this.$message({
            message: "保存成功",
            type: "success",
          });
        }
      } catch (error) {
        this.$message({
          message: error.message,
          type: "error",
        });
      }

    },
    async saveSort(row) {
      if (row && row.sort != "") {
        let ajax = {
          orderBy: row.orderBy,
          articleTypeId: row.articleTypeId
        }
        const result = await upArticleType(ajax);
        this.$message({
          type: "success",
          message: "保存成功",
        });
        let data = {
          name: this.filterName
        };
        await this.queryPage(data);
      } else {
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    // filterChildren(arr,key){
    //     let vals = arr.filter(v => !v[key]);
    //     return vals;
    // },
    async determine() {
      // if(!this.childTypeList.length){
      //     this.$message.warning('请添加子分类！');
      //     return;
      // };
      // const res = await this.filterChildren(this.childTypeList,'name');
      // if(res.length){
      //     this.$message.warning('子分类不可为空！');
      //     return;
      // };
      if (this.upDate == 1) {
        this.baocun();
      } else {
        if (this.childTypeList.length) {
          const data = {
            isMyself: 0,
            classType: this.articleTypeId
          };
          selectWorkDesc(data)
              .then(res => {
                if (res.code == 200) {
                  if (this.chooseIn == 0) {
                    this.childTypeList.forEach((item, index) => {
                      item.value = index + 1
                      item.label = item.name
                    })
                    this.queryChildType = this.childTypeList
                    this.typeTole = res.data.responseList.length
                    this.activeTypeDialogVisible = true
                    this.feilei = 1
                  } else {
                    this.baocun()
                  }
                } else {
                  this.baocun()
                }
              })
              .catch(function (err) {
              });
        } else {
          this.baocun()
        }

      }

      // if (this.childTypeList.length == 0) {
      //     this.baocun()
      // } else {
      //     const data = {
      //         isMyself: 0,
      //         classType: this.articleTypeId
      //     }
      //     selectWorkDesc(data)
      //         .then(res => {
      //             if (res.code == 200) {
      //                 if (this.chooseIn == 0) {
      //                     this.childTypeList.forEach((item, index) => {
      //                         item.value = index + 1
      //                         item.label = item.name
      //                     })
      //                     this.queryChildType = this.childTypeList
      //                     this.typeTole = res.data.responseList.length
      //                     this.activeTypeDialogVisible = true
      //                     this.feilei = 1
      //                 } else {
      //                     this.baocun()
      //                 }
      //             } else {
      //                 this.baocun()
      //             }
      //         })
      //         .catch(function (err) {
      //         });
      // }
    },
    baocun() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              name: this.ruleForm.title,
              orderBy: this.ruleForm.sort,
              status: this.ruleForm.isState,
              type: 1,
              articleTypeChilds: this.childTypeList, // 添加子分类
              identificaType: this.ruleForm.identificaType
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addArticleType(data);
            } else {
              data.articleTypeId = this.articleTypeId
              await upArticleType(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    filterNames() {
      this.currentPage = 1;
      let data = {
        name: this.filterName
      };
      this.queryPage(data);
    },
    /**@method 模态框关闭的回调 */
    close() {
      this.activeTypeDialogVisible = false;
    },
    close1() {

      (this.ruleForm = {
        title: "",
        sort: "",
        isState: 0
      }),
          this.centerDialogVisible = false;
      this.chooseIn = 0;
      this.feilei = 0
    },
  }
};
</script>

<style lang="scss" scoped>
.childTypeList {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.childTypeListL {
  flex: 1;
  margin-right: 10px;
  display: flex;
}
</style>

